<template>
  <div
    class="navigation-section !bg-transparent w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative mb-[5px] gold"
      :class="[
        item.color,
        { 'active': item.isActive }
      ]"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        height="24"
        width="24"
        class="icon"
        :class="item.iconColor"
      />
      <div
        v-if="navState === 'full'"
        class="label !font-bold inline-flex justify-between w-full"
      >
        <div class="lhs flex flex-col">
          <div v-if="item.title" class="text-green-bright text-xs">
            {{ item.title }}
          </div>
          <span class="text-sm">{{ item.label }}</span>
          <span v-if="item.leaderboardName && !loading" class="my-auto text-orange-dark h-3 text-xs">{{ days > 0 ? `${days}d` : 'Today' }}</span>
        </div>

        <div class="rhs flex my-auto text-green-bright">
          <span class="text-xs">
            ${{ Number(item.value).toLocaleString(undefined, { maximumFractionDigits: 0 }) }}
          </span>
        </div>
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';
import { useDynamicMillionaire } from '~/composables/useDynamicMillionaire';

const route = useRoute();
const { $config, } = useNuxtApp();

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const { competitionPrizePool, } = useDynamicMillionaire($config?.public?.ENV_NAME === 'Dev' ? 3318 : 27537);

const days = ref(0);
const loading = ref(true);

const items = computed(() => [
  {
    icon: 'lightning',
    color: 'millionaire',
    title: 'Prize Builder',
    iconColor: 'millionaire-icon',
    leaderboardName: null,
    label: 'Metawin Millionaire',
    value: competitionPrizePool.value,
    isActive: route.path.includes(`competition/${$config?.public?.ENV_NAME === 'Dev' ? 3318 : 27537}`),
    action: () => sportsBettingRedirect(`/competition/${$config?.public?.ENV_NAME === 'Dev' ? 3318 : 27537}`),
  },
]);
</script>

<style lang="scss" scoped>
.millionaire {
  background: linear-gradient(90deg, rgba(3, 130, 28, 0.5) 0%, rgba(21, 23, 29, 0.25) 100%);
}

.millionaire .label {
  color: white;
}

.millionaire:hover {
  background: rgba(3, 130, 28, 0.5);
}

.millionaire-icon {
  color: rgba(7, 197, 45, 0.5);
}
</style>
