<template>
  <div
    class="navigation-section !bg-transparent w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative mb-[5px]"
      :class="[
        item.color,
        { 'active': item.isActive }
      ]"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        :class="item.iconColor"
        class="icon"
        height="24"
        width="24"
      />
      <div v-if="navState === 'full'" class="label !font-bold justify-between w-full flex flex-col">
        <div v-if="item.title" class="text-[#E01515] text-xs">
          {{ item.title }}
        </div>
        <span class="text-sm">{{ item.label }}</span>
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const items = computed(() => [
  {
    icon: 'vip-crown',
    color: 'vip-transfer',
    iconColor: 'vip-transfer-icon',
    title: 'Season 3',
    label: 'VIP Transfer',
    isActive: route.path.includes('vip-transfer-season3'),
    showDays: false,
    action: () => sportsBettingRedirect('/promotions/vip-transfer-season3/'),
  },
]);
</script>

<style lang="scss" scoped>
.vip-transfer {
  background: linear-gradient(90deg, rgba(137, 12, 12, 0.5) 0%, rgba(21, 23, 29, 0.25) 100%);

  &:hover {
    background: rgba(137, 12, 12, 0.5);
  }

  .label {
    color: white;
  }

  &-icon {
    color: #FF0000;
  }
}
</style>
