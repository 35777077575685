<template>
    <transition name="slidedown-fade-fast" mode="out-in">
        <div
            v-if="activeReward"
            class="flex w-full flex-col mb-2 py-1 border-b border-b-slate-500 px-1 animate-slide-down-fade-in03s"
            >
            <h4 class="mb-1">
                {{ activeReward.template.name }}
            </h4>
            <div class="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                <div class="bg-blue-600 h-1 rounded-l transition-[width] duration-300" :style="{ 'width': `${activeReward.progress}%` }"/>
            </div>
            <div class="flex justify-between">
                <div class="text-sm font-bold mt-[2px]">
                    {{ activeReward.progress }}%
                </div>
                <div class="flex items-center">
                    <SvgIcon
                        :icon="activeReward.currencyCode"
                        width="16"
                        height="16"
                        class="mr-1"
                        />
                    {{ activeReward.amount }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { storeToRefs } from 'pinia';

const rewardStore = useRewardStore();
const { pendingRewards, } = storeToRefs(rewardStore);

const sortedRewards = computed(() => {
  pendingRewards.value.forEach((reward) => {
    reward.priority = reward.createTime === reward.updateTime ? 2 : 1;
  });

  return _OrderBy(pendingRewards.value, ['priority', 'createTime', 'updateTime',], ['asc', 'asc', 'desc',]);
});

const activeReward = computed(() => sortedRewards.value[0]);
</script>
