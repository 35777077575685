<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div
      :class="{ 'active': category.isActive }"
      class="flex items-stretch drop-down"
      >
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="label">
          {{ category.label }}
        </div>
        <NavigationToolTip :label="category.label"/>
      </button>
      <button
        v-if="dropdownEnabled"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="casinoNavIsCollapsed = !casinoNavIsCollapsed"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180 text-white': !casinoNavIsCollapsed }"
        />
      </button>
    </div>
    <div v-if="!casinoNavIsCollapsed && dropdownEnabled" class="dropdown-content mt-1">
      <button
        v-for="item in items"
        :key="item.icon"
        class="flex items-center nav-button nav-sub rounded-md w-full relative"
        :class="{ 'active': item.isActive }"
        size="2xs"
        @click.prevent.stop="item.action()"
      >
        <SvgIcon
          :icon="item.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="label">
          {{ item.label }}
        </div>
        <NavigationToolTip :label="item.label"/>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, casinoNavIsCollapsed, } = storeToRefs(uiStore);

const sportsBettingStore = useSportsBettingStore();
const { redirectToPath, triggeredFromNav, } = storeToRefs(sportsBettingStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const dropdownEnabled = computed(() => navState.value === 'full');
const category = computed(() => {
  return {
    icon: 'baccarat',
    label: 'Instant Win',
    isActive: !casinoNavIsCollapsed.value,
    action: () => {
      casinoNavIsCollapsed.value = false;
      redirectToPath.value = '/';
      triggeredFromNav.value = false;
      sportsBettingRedirect('/games/');
    },
  };
});
const items = computed(() => [
  {
    icon: 'allGames',
    label: 'MetaWin Originals',
    isActive: route.path.includes('collection/originals'),
    action: () => sportsBettingRedirect('/games/collection/originals/'),
  },
  {
    icon: 'rocket',
    label: 'Popular Slots',
    isActive: route.path.includes('collection/popular'),
    action: () => sportsBettingRedirect('/games/collection/popular/'),
  },
  {
    icon: 'fire',
    label: 'New Releases',
    isActive: route.path.includes('collection/new'),
    action: () => sportsBettingRedirect('/games/collection/new/'),
  },
  {
    icon: 'liveStreams',
    label: 'Live Casino',
    isActive: route.path.includes('live-games') && !route.path.includes('collection/live-games'),
    action: () => sportsBettingRedirect('/live-games/'),
  },
  {
    icon: 'blackjack',
    label: 'Blackjack',
    isActive: route.path.includes('collection/blackjack'),
    action: () => sportsBettingRedirect('/games/collection/blackjack/'),
  },
  {
    icon: 'roulette',
    label: 'Roulette',
    isActive: route.path.includes('collection/roulette'),
    action: () => sportsBettingRedirect('/games/collection/roulette/'),
  },

  {
    icon: 'baccarat',
    label: 'Baccarat',
    isActive: route.path.includes('collection/baccarat'),
    action: () => sportsBettingRedirect('/games/collection/baccarat/'),
  },
  {
    icon: 'bonusBuy',
    label: 'Bonus Buys',
    isActive: route.path.includes('collection/bonus-buy'),
    action: () => sportsBettingRedirect('/games/collection/bonus-buy/'),
  },
  {
    icon: 'plinkonav',
    label: 'Plinkos',
    isActive: route.path.includes('collection/plinkos/'),
    action: () => sportsBettingRedirect('/games/collection/plinkos/'),
  },
  {
    icon: 'Present',
    label: 'Live Gameshows',
    isActive: route.path.includes('collection/live-gameshows'),
    action: () => sportsBettingRedirect('/games/collection/live-gameshows/'),
  },
  {
    icon: 'chip',
    label: 'Table Games',
    isActive: route.path.includes('collection/live-gameshows'),
    action: () => sportsBettingRedirect('/games/collection/table-games/'),
  },
  {
    icon: 'joystick',
    label: 'Providers',
    isActive: route.path.includes('providers'),
    action: () => sportsBettingRedirect('/providers/'),
  },
  {
    icon: 'buyBonus',
    label: 'All Games',
    isActive: route.path.includes('collection/all'),
    action: () => sportsBettingRedirect('/games/collection/all/'),
  },
]);
</script>

<style lang="scss" scoped>
.navigation-section {
  .category {
    color: white;

    &.active {
      color: white;
    }
  }

  .active {
    color: white;
  }
}
</style>
